code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.navbar-link {
	padding: 10px;
	color: rgb(100, 46, 100);
}

.navbar-link:hover {
	color: rgb(161, 73, 161);
}

.navbar-light .navbar-brand {
	padding-left: 5px;
	color: pink;
}

.container-fluid {
	padding: 0;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #ffffff;
	// background-color: #0a9494;
}

.component-height {
	height: calc(100% - 56px);
}

.circle-btn {
	position: fixed;
	bottom: 10%;
	right: 15%;
	float: right;
	border-radius: 100%;
	height: 60px;
	width: 60px;
	background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%);
	transition: none;
}

.circle-btn:hover {
	background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%);
	border: none;
	transition: none;
}

.undo-redo-btn {
	float: right;
	border: none;
	background: none;
	color: rgba(211, 211, 211, 0.733);
}

.form-input {
	background: none;
	margin: 2px;
	border: none;
	border-bottom: solid 1px rgb(123, 0, 148);
	border-radius: 1px;
	color: white;
}

.form-input:focus {
	background: none;
	border: none;
	box-shadow: none;
	color: white;
	border-bottom: 2px solid rgb(123, 0, 148);
}

.form-input:disabled {
	background: none;
	border-bottom: 1px solid rgba(123, 0, 148, 0.295);
}

.form-input-label {
	float: right;
	color: rgb(123, 0, 148);
}

.form-input-label-disabled {
	float: right;
	color: rgba(123, 0, 148, 0.295);
}

.save-image {
	width: 100%;
}

hr {
	margin: 0 auto;
	width: 90%;
}

.bullet-point {
	font-size: 20px;
	color: darkblue;
}

.image-conatiner {
	background-color: black;
	color: white;
}

.nav-row {
	background-color: rgb(110, 120, 170);
	color: white;
	height: 4.5em;
}

.navbar-brand {
	color: white;
	padding-left: 10%;
}

.navbar-link {
	color: whitesmoke;
}

.profile-image-conatiner {
	background-color: black;
	color: white;
	height: 250px;
	width: 500px;
}

.border-color {
	border: 0;
	border-top: 1px solid;
	color: inherit;
	margin: 1rem 0;
	opacity: 0.25;
}

.nav-brand-logo {
  width: auto;
  height: 3em;
}

.logo-page-level {
  width: auto;
  height: 15em;
  border-radius: 5px;
}
