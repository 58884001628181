.hammer-book-image {
	width: 50em;
	height: 16em;
}

.smart-image {
    width: 30em;
	height: 20em;
}

.footer {
    background-color: rgb(110, 120, 170);
	color: white;
	height:5em;
}